//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DirectDownloadIcon from '../components/icons/DirectDownloadIcon';
import InputButton from '../components/inputs/InputButton';
export default {
  components: {
    DirectDownloadIcon,
    InputButton
  },
  data(){
    return {
      priceChosen: null,
    }
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    extendedView: {
      type: Boolean,
      default: false,
    },
    lazyLoading: {
      type: Boolean,
      default: true,
      required: false
    },
    brandPage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    brand(){
      let i = 0;
      let brands = this.$store.state.entities.brands;
      let brandsLength = brands.length;
      while(i < brandsLength){
        if(brands[i].id === this.product.brand_id){
          return brands[i];
        }
        i += 1;
      }
      //let brands = this.$store.state.entities.brands;
      //let brandsLength = brands.length;
      //for (let i = 0; i < brandsLength; i++){
      //  if (brands[i].id === this.product.brand_id){
      //    return brands[i];
      //  }
      //}
    },
    totalReviewsCount(){
      return this.product.client_reviews_count * this.$review_multiplier.product();
    },
    securityLevel(){
      let i = 0;
      let securityLevels = this.$store.state.entities.securityLevels;
      let securityLevelsLength = securityLevels.length;
      while(i < securityLevelsLength){
        if(securityLevels[i].id === this.product.security_level_id){
          return securityLevels[i];
        }
        i += 1;
      }
      //let securityLevels = this.$store.state.entities.securityLevels;
      //let securityLevelsLength = securityLevels.length;
      //for (let i = 0; i < securityLevelsLength; i++){
      //  if (securityLevels[i].id === this.product.security_level_id){
      //    return securityLevels[i];
      //  }
      //}

    },
    prices(){
        return [...this.$store.getters.productFinalPricesById[this.product.id]]
            .filter(p => p.stock > 0)
            .sort((a, b) => a.licences - b.licences);
    },
  },
  watch: {
    prices: {
      handler(prices){
        if(this.priceChosen){
          //El nuevo precio para misma cantidad de licencias
          this.priceChosen = this.prices.find(pr => pr.licences === this.priceChosen.licences) || null;
        }else{
          //La primer opcion
          this.priceChosen = prices[0] || null;
        }
      },
      immediate: true,
    }
  },
  methods: {
    addToCartHandler(){
      if(this.priceChosen){
        this.$store.dispatch('cart/addToCart', {product: this.product, licences: this.priceChosen.licences});
        this.$tracking.addToCart(this.product, this.priceChosen.licences);
        this.$tracking.v4.addToCart(this.product, this.priceChosen.licences);
        this.$router.push(this.localePath({name: 'cart'}));
      }
    },
    trackAndRedirect() {
      this.$emit('track');

      this.$router.push(this.localePath(`/${this.brand.slug}/${this.product.slug}`));
    }
  },
}
