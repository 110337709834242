import { render, staticRenderFns } from "./ProductBox.vue?vue&type=template&id=82a2a6e8&scoped=true&"
import script from "./ProductBox.vue?vue&type=script&lang=js&"
export * from "./ProductBox.vue?vue&type=script&lang=js&"
import style0 from "./ProductBox.vue?vue&type=style&index=0&id=82a2a6e8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82a2a6e8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DiscountLabel: require('/var/www/sites/antivirus.codes/components/DiscountLabel.vue').default,SecurityLevelLabel: require('/var/www/sites/antivirus.codes/components/SecurityLevelLabel.vue').default,RatingStars: require('/var/www/sites/antivirus.codes/components/RatingStars.vue').default})
