//
//
//
//
//
//
//
//
//

export default {
    name: 'InputButton',
    props: {
        icon: {
            type: String,
            required: false
        },
        text: {
            type: String,
            required: false
        },
        i18n: {
            type: String,
            required: false
        },
        buttonType: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        computedText() {
            if (this.i18n) {
                return this.$t(this.i18n);
            } else {
                return this.text;
            }
        }
    },
    methods: {
        emitClick() {
            this.$emit('click');
        }
    }
};
