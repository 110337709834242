//
//
//
//
//
//
//
//
//
//
//
//
//

import StarOrange from '~/assets/img/icons/icon_star_orange_24.svg';
import StarBlue from '~/assets/img/icons/icon_star_blue_24.svg';

export default {
  props: {
    greyed: {
      type: Boolean,
      default: false,
      required: false
    },
    gap: {
      type: Number,
      required: false
    },
    type: {
      type: String,
      required: false,
      validate(value) {
        return ['blue'].includes(value);
      }
    },
    rating: {
      type: Number,
      required: true,
      validate(value) {
        return value >= 0 && value <= 5;
      }
    },
    size: {
      type: Number,
      required: false,
      default: 20,
    }
  },
  computed: {
    stars() {
      const stars = [];
      const length = this.greyed ? 5 : Math.min(Math.round(this.rating), 5);

      for (let i = 1; i <= length; i++) {
        // Conseguir una estrella y partirla a la mitad
        stars.push({
          icon: this.type === 'blue' ? StarBlue : StarOrange, // icon: fill >= 1 ? StarOrangeIcon : (fill >= 0.5 ? StarHalfIcon : StarEmptyIcon),
          greyed: this.rating < i
        });
      }

      return stars;
    },
    style() {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
        marginRight: `${this.gap}px`
      };
    }
  }
};
