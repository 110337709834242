// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/icons/iconmonstr-badge-4-icon.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".product-box-wrapper[data-v-46f8df8c]{width:100%;margin:0 auto;padding:40px 0;max-width:1040px}@media screen and (max-width:667px){.product-box-wrapper[data-v-46f8df8c]{max-width:calc(100vw - 60px)}}.product-box-wrapper[data-v-46f8df8c]{display:flex;justify-content:space-between}@media screen and (max-width:1040px){.product-box-wrapper[data-v-46f8df8c]{flex-direction:column;align-items:center}}.discount-label[data-v-46f8df8c]{background-color:#ff7a00;color:#fff;font-family:\"Montserrat\",\"Raleway\",sans-serif;margin-bottom:15px;padding:20px 8px;border-radius:100%;position:relative;clear:both;width:75px;height:75px;display:inline-flex;align-items:center}.discount-label.tiny_banner[data-v-46f8df8c]{padding:4px}.discount-label.tiny_banner .amount[data-v-46f8df8c]{font-size:12px}.discount-label.with-tick .label .text[data-v-46f8df8c]{font-weight:400}.discount-label.star[data-v-46f8df8c]{font-size:22px;line-height:25px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:contain;height:150px;width:150px;display:flex;justify-content:center;align-items:center;flex-direction:column}.discount-label.star .amount[data-v-46f8df8c]{font-size:41px}.discount-label.star .star-text[data-v-46f8df8c]{font-weight:800}.discount-label>*[data-v-46f8df8c]{display:inline-block}.discount-label .star-text[data-v-46f8df8c]{margin-top:.25rem;font-size:13px}.discount-label .amount[data-v-46f8df8c]{font-size:1.25rem;font-family:\"Montserrat\",\"Raleway\",sans-serif;font-weight:800;line-height:1.75rem}.discount-label .text>*[data-v-46f8df8c]{display:block;font-size:.75em;line-height:14px;text-align:left}.product-resume.home-product-resume .discount-container[data-v-46f8df8c]{text-align:left}.product-result .discount-container[data-v-46f8df8c]{padding:10px 12px}.product-result .discount-container .discount-label .amount[data-v-46f8df8c]{font-size:2rem;margin-right:5px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
