//
//
//
//
//
//
//

export default {
    props: {
        width: {
            type: String,
            required: false,
            default: '24'
        }
    }
}
