//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    percentage: {
      type: Number,
      required: true,
    },
    tick: {
      type: Boolean,
      default: false,
    },
    showNegative: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'label',
      validate(type){
        return [
          'label',
          'label-tiny',
          'label-tiny-banner',
          'star',
        ].includes(type);
      }
    }
  }
}
